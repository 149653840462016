/**
 * Generic module loader
 * Loads a module if the condition function returns true.
 * Returns a promise to the loaded class.
 */
export default class Loader {
    /**
     * @param module {string} module name, needs to be in the "components" directory
     * @param condition {function} function returning true if the module should be loaded
     */
    static load(module, condition) {
        try {
            return Loader._doLoad(module, condition);
        } catch(err) {
            Loader.log(err, 'error');
        }
    }

    static async _doLoad(module, condition, message = '') {
        if (typeof condition !== 'function') {
            Loader.log(`Invalid condition - function expected [Loader(${module}, ${condition})]`, 'error');
            return;
        }

        if (typeof module !== 'string') {
            Loader.log(`Invalid module path - string expected [Loader(${module}, ${condition})]`, 'error');
            return;
        }

        if (condition()) {
            if (!message) message = 'condition met';
            Loader.log(`${message}, loading: ${module}`);

            try {
                return  (await import(/* webpackChunkName: "[request]" */ `../components/${module}`)).default;

            } catch(err) {
                Loader.log(`Module ${module} not loaded`, 'warn');
            }
        }
    }

    static log(msg, type = 'log') {
        if (document.cookie.indexOf('dev=') >= 0) {
            console[type](msg);
        }
    }
}
