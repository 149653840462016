import Loader from './loader';

/**
 * Loads a module if page URL matches the specified regular expression
 * @extends Loader
 */
export default class LoaderUrl extends Loader {
    /**
     * @param module {string} module name, needs to be in the "components" directory
     * @param regexp {string}
     */
    static load(module, regexp) {
        if (typeof module !== 'string') {
            Loader.log(`Invalid module path - string expected [LoaderUrl(${module}, ${regexp})]`, 'error');
            return;
        }
        if (typeof regexp !== 'string') {
            Loader.log(`Invalid url - string expected [LoaderUrl(${module}, ${regexp})]`, 'error');
            return;
        }

        return super._doLoad(module, LoaderUrl.condition(regexp), `url matches '${regexp}'`);
    }

    static condition(regexp) {
        return () => {
            return window.location.pathname.match(new RegExp(regexp)) !== null;
        }
    }
}
