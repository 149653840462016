import Loader from './loader';

/**
 * Loads a module if the indicated DOM element is present
 * @extends Loader
 */
export default class LoaderSelector extends Loader {
    /**
     * @param module {string} module name, needs to be in the "components" directory
     * @param selector {string} css selector string, in document.querySelector syntax
     */
    static load(module, selector) {
        if (typeof module !== 'string') {
            Loader.log(`Invalid module path - string expected [LoaderSelector(${module}, ${selector})]`, 'error');
            return;
        }
        if (typeof selector !== 'string') {
            Loader.log(`Invalid selector - string expected [LoaderSelector(${module}, ${selector})]`, 'error');
            return;
        }

        try {
            return super._doLoad(module, LoaderSelector.condition(selector), `selector '${selector}' found in DOM`);
        } catch(err) {
            Loader.log(err, 'error');
        }
    }

    static condition(selector) {
        return () => {
            return document.querySelector(selector) !== null;
        }
    }
}
