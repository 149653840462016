import './vendors/utils';
import LoaderSelector from './base/loader-selector';
import LoaderUrl from './base/loader-url';

class App {
	constructor() {
		this.init();
	}

	async init() {
		// CHATBOT
    try {
      new (await LoaderSelector.load('solar-chatbot', '.m2-extension__chatbot'))();
    } catch (e) {}

    /*
    try {
      new (await LoaderUrl.load('chatbot-materace', '(-bm001)|(-bm002)|(-24828)|(-24823)|(-pubee280ef7)|(-pub9bfc0bb0)|(-pubea2fa5d0)|(-20533)|(-bm003)|(-16284)|(-40451284)|(-70451287)|(-10451290)|(-50451293)|(-00450615)|(-30450609)|(-40450618)|(-40450623)|(-00450658)|(-10450667)|(-40450680)|(-50450689)|(-20450742)|(-50450745)|(-30450751)|(-70450754)|(-10476399)|(-50476401)|(-20476407)|(-90476404)|(-90404799)|(-20404806)|(-30446373)|(-60249470)|(-40249471)|(-50228717)|(-00249487)|(-30228718)|(-30372503)|(-50412683)|(-60446376)|(-30452651)|(-40269421)|(-60372506)|(-00412685)|(-00404812)|(-90446351)|(-00349849)|(-40395245)|(-80242095)|(-40349847)|(-50242092)|(-80372789)|(-70395239)|(-60420932)|(-30446354)|(-80489666)|(-90489449)|(-20535)|(-20536)|(-20534)|(-46083)|(-pub6ba9d416)|(-sen-z-ikea-pub68c5ca50)|(-pub35904700)'))();
    } catch (e) {}
    */

  // FORMS
		try {
			new (await LoaderSelector.load('privacy-form', '.m2-extension__r-privacyForm'))();
    } catch (e) {}

    //PIXELS AND MORE
    try {
      new (await LoaderUrl.load('adnxs-one', '-tl004'))();
    } catch (e) {}
	}
}

document.addEventListener('DOMContentLoaded', () => new App());
