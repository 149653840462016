!(function(t) {
    function e(o) {
        if (n[o]) return n[o].exports;
        const r = n[o] = {i: o, l: !1, exports: {}};
        return t[o].call(r.exports, r, r.exports, e), r.l = !0, r.exports;
    }

    var n = {};
    e.m = t, e.c = n, e.d = function(t, n, o) {
        e.o(t, n) || Object.defineProperty(t, n, {configurable: !1, enumerable: !0, get: o});
    }, e.n = function(t) {
        const n = t && t.__esModule ? function() {
            return t.default;
        } : function() {
            return t;
        };
        return e.d(n, 'a', n), n;
    }, e.o = function(t, e) {
        return Object.prototype.hasOwnProperty.call(t, e);
    }, e.p = 'https://www.ikea.com/pl/pl/', e(e.s = 1);
}
([function(t, e, n) {
    'use strict';

    const o = function(t) {
        'querySelector' in document && 'localStorage' in window && 'addEventListener' in window && t();
    };
    t.exports = o;
}, function(t, e, n) {
    'use strict';

    n(2), n(0)(() => {
        n(3), n(6);
    });
},
  function(t, e) {
},
  function(t, e, n) {
    'use strict';

    function o(t, e) {
        const n = r(e);
        if (n) {
            // const o = document.createElement('span');
            // (0, c.addClass)(o, 'header__shopping-bag-quantity'), o.innerHTML = n > a ? `${a}+` : n, t.appendChild(o);
        }
    }

    function r(t) {
        let e = t.split('-')[1],
            n = parseInt(s.default.get(`IRMW_CART_COUNT_${e}`), 10);
        return isNaN(n) ? 0 : n;
    }

    var c = n(4),
        i = n(5),
        s = (function(t) {
            return t && t.__esModule ? t : {default: t};
        }(i)),
        a = 99,
        u = document.querySelector('.js-shopping-cart-icon');
    u && (function(t) {
        const e = t.dataset.marketCode;
        e && (o(t, e), document.body.addEventListener('addtocart', () => {
            o(t, e);
        }, !1));
    }(u));
},
  function(t, e, n) {
    'use strict';

    function o(t, e) {
        Object.keys(e).forEach(n => {
            return t.setAttribute(n, e[n]);
        });
    }

    let r = function(t, e) {
            const n = new RegExp(`(^|\\s)${e}(\\s|$)`).test(t.className);
            return t.className && n;
        },
        c = function(t, e) {
            return r(t, e) || (t.className = (`${t.className} ${e}`).trim()), t;
        },
        i = function(t, e) {
            if (r(t, e)) {
                const n = new RegExp(`(^|\\s+)${e}(\\s+|$)`);
                t.className = t.className.replace(n, ' ').trim();
            }
            return t;
        };
    t.exports = {hasClass: r, addClass: c, removeClass: i, setAttributes: o};
}, function(t, e, n) {
    'use strict';

    const o = {
        get: function(t) {
            const e = (`; ${document.cookie}`).split(`; ${t}=`);
            return e.length === 2 ? e.pop().split(';').shift() : '';
        },
        set: function(t, e, n, o, r) {
            let c = `${t}=${e}`,
                i = n ? `;domain=${n}` : '',
                s = o ? `;path=${o}` : '',
                a = r ? `;expires=${r}` : '';
            document.cookie = c.concat(i, s, a);
        }
    };
    t.exports = o;
}, function(t, e, n) {
    'use strict';

    function o() {
        return JSON.parse(localStorage.getItem('cookieInfoShown'));
    }

    function r() {
        localStorage.setItem('cookieInfoShown', JSON.stringify(!0)), c.classList.remove('cookie-info__panel--show');
    }

    var c = document.querySelector('.js-cookie-info__panel');
    c && (function() {
        o() || (c.classList.add('cookie-info__panel--show'), document.querySelector('.js-cookie-info__accept-button').addEventListener('click', r));
    }());
}]));



